export const horizontallyReorderFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![Horizontally reorder floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const horizontallyReorderFloorArea = {
  order: 35,
  name: "Horizontally reorder floor areas",
  keyword: "move",
  subcategory: "Properties",
  markdown: `# Horizontally reorder floor areas

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a {% inlineRouterLink articleId="add-building-levels" %}building level{% /inlineRouterLink %} contains multiple {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %}, it may become necessary to change the horizontal order of the floor areas to better approximate their real-world placement. Read on to learn how to do this in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** To use this feature the floor areas must be attached to building levels. If they are attached to {% inlineRouterLink articleId="add-a-land-covering" %}land coverings{% /inlineRouterLink %}, you can {% inlineRouterLink articleId="vertically-reorder-floor-areas" %}vertically reorder{% /inlineRouterLink %} them.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %} that is attached to a {% inlineRouterLink articleId="add-building-levels" %}building level{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="landCoveringLevelHorizontalReorder" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}Click and drag any of the floor areas left or right within the current building level.
  5. Once the order of all floor areas is correct, click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$horizontallyReorderFloorAreaVisuals /%}
  `,
};
